.footer{

  color: black;
  width: 100%;
  height: 100%;
}

.footer ul {
  display: flex;
  padding-inline-start:unset;
  max-width: 1140px;
  margin: auto;
}

.footer ul li{
  list-style: none;
  margin: 20px 5px 20px 5px;
  text-decoration: none;
  opacity: 0.3;
  transition: opacity 0.7s ease;
}

.footer ul li:first-child{
  margin-left: 10px;
}

.footer ul li.active{
  opacity: 1;
}

.svg-inline--fa.fa-w-14 {
  width: 2em;
}

.svg-inline--fa.fa-w-16 {
  width: 2em;
}


.svg-inline--fa{
  height: 2em;
  color: black
}

.footer ul li:hover{
  opacity:1;
}
.footer ul li a:link{
  text-decoration: none;
  color: black;
}
