@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.wrap{
  max-width: 1144px;
  margin: auto;
  padding: 0 15px;
  display: flex;
}

.wrap .about-me-description p{
  margin-top: 0;
  padding-top: 0;
  margin-left: 15px;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
}

.wrap .about-me-image img {
  width: 50vw;
  max-width: 572px;
}

@media only screen and (max-width: 500px) {
  .wrap{
    display: block;

  }
  .wrap .about-me-description p {
    margin: 0;
  }


    .wrap .about-me-image img {
      width: 100%;
    }
}
