@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
   font-family: 'Morganite';
   font-style: normal;
   font-weight: 400;
   src: url(/static/media/Morganite-Light.3dcfba97.ttf) format('truetype');

}


.slick-slide div img{
  height: 66vh;


}


@media only screen and (max-width: 1025px) {
  .slick-slide div img{

    max-height: 500px;

  }
}
.slick-prev, .slick-next{
  height:30px;
  width: 30px;
}
.slick-prev{
  left: 25px;
  z-index: 1;
}

.slick-next{
  right: 25px;
}

.slick-prev::before, .slick-next::before{
  font-size:30px;
}

.slick-slide{
  opacity: 0.7;
  transition: opacity 0.7s ease;
}

.slick-slide img.slide-img{
  width:unset!important;
}

.slick-current{
  opacity: 1;
}

.slick-slide:hover{
  opacity: 1;
}

.navbar{
  min-height:100px;
  font-family: morganite;
  font-size: 48px;
  max-width: 1144px;
  margin: auto;
  padding-top: 40px;
  transition: font-size 0.5s ease;
}

.navbar span{
  margin: 15px;
  font-size: 60px;
}
.navbar ul{
  display: -webkit-flex;
  display: flex;
  -webkit-padding-start:unset;
          padding-inline-start:unset;
  margin-top: unset;
  margin-bottom: unset;
}

.navbar ul li{
  list-style: none;
  margin: 15px;
  text-decoration: none;
  opacity: 0.4;
  transition: opacity 0.7s ease;
}
.navbar ul li.active{
  opacity: 1;
}

.navbar ul li:hover{
  opacity:1;
}
.navbar ul li a:link{
  text-decoration: none;
  color: black;
}

.navbar ul li a:visited{
  color: black;
}

@media only screen and (max-width: 375px) {
  .navbar{
    font-size: 30px;
  }

}

  @media only screen and (max-width: 500px) {

    .navbar ul li:first-child{
      margin-left: 15px;
    }

    .navbar ul li:last-child{
      margin-right: 15px;
    }

    .navbar ul li{
      margin: auto;
    }
}

.footer{

  color: black;
  width: 100%;
  height: 100%;
}

.footer ul {
  display: -webkit-flex;
  display: flex;
  -webkit-padding-start:unset;
          padding-inline-start:unset;
  max-width: 1140px;
  margin: auto;
}

.footer ul li{
  list-style: none;
  margin: 20px 5px 20px 5px;
  text-decoration: none;
  opacity: 0.3;
  transition: opacity 0.7s ease;
}

.footer ul li:first-child{
  margin-left: 10px;
}

.footer ul li.active{
  opacity: 1;
}

.svg-inline--fa.fa-w-14 {
  width: 2em;
}

.svg-inline--fa.fa-w-16 {
  width: 2em;
}


.svg-inline--fa{
  height: 2em;
  color: black
}

.footer ul li:hover{
  opacity:1;
}
.footer ul li a:link{
  text-decoration: none;
  color: black;
}

.wrap{
  max-width: 1144px;
  margin: auto;
  padding: 0 15px;
  display: -webkit-flex;
  display: flex;
}

.wrap .about-me-description p{
  margin-top: 0;
  padding-top: 0;
  margin-left: 15px;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
}

.wrap .about-me-image img {
  width: 50vw;
  max-width: 572px;
}

@media only screen and (max-width: 500px) {
  .wrap{
    display: block;

  }
  .wrap .about-me-description p {
    margin: 0;
  }


    .wrap .about-me-image img {
      width: 100%;
    }
}


