
.slick-slide div img{
  height: 66vh;


}


@media only screen and (max-width: 1025px) {
  .slick-slide div img{

    max-height: 500px;

  }
}
.slick-prev, .slick-next{
  height:30px;
  width: 30px;
}
.slick-prev{
  left: 25px;
  z-index: 1;
}

.slick-next{
  right: 25px;
}

.slick-prev::before, .slick-next::before{
  font-size:30px;
}

.slick-slide{
  opacity: 0.7;
  transition: opacity 0.7s ease;
}

.slick-slide img.slide-img{
  width:unset!important;
}

.slick-current{
  opacity: 1;
}

.slick-slide:hover{
  opacity: 1;
}

.navbar{
  min-height:100px;
  font-family: morganite;
  font-size: 48px;
  max-width: 1144px;
  margin: auto;
  padding-top: 40px;
  transition: font-size 0.5s ease;
}

.navbar span{
  margin: 15px;
  font-size: 60px;
}
.navbar ul{
  display: flex;
  padding-inline-start:unset;
  margin-top: unset;
  margin-bottom: unset;
}

.navbar ul li{
  list-style: none;
  margin: 15px;
  text-decoration: none;
  opacity: 0.4;
  transition: opacity 0.7s ease;
}
.navbar ul li.active{
  opacity: 1;
}

.navbar ul li:hover{
  opacity:1;
}
.navbar ul li a:link{
  text-decoration: none;
  color: black;
}

.navbar ul li a:visited{
  color: black;
}

@media only screen and (max-width: 375px) {
  .navbar{
    font-size: 30px;
  }

}

  @media only screen and (max-width: 500px) {

    .navbar ul li:first-child{
      margin-left: 15px;
    }

    .navbar ul li:last-child{
      margin-right: 15px;
    }

    .navbar ul li{
      margin: auto;
    }
}
